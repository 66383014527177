.context-menu {
  background-color: #FEFEFD;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  width: 30%;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);

  .container {
    width: 98%;
    padding-top: 5%;
    padding-bottom: 4%;
    cursor: pointer;
  }

  .context-option {
    color: #818283;
    padding-bottom: 3%;
  }

  .image {
    width: 10%;
    height: 10%;
    user-select: none;
  }

  .location-preview {
    border-radius: 5px;
  }

  .no-preview {
    opacity: 0.5;
    border-radius: 5px;
  }

  .search {
    user-select: none;
  }

  .scrollable {
    max-height: 15em;
    overflow: auto;
    overflow-x: hidden
  }

  .text {
    padding-left: 5%;
    color: #818283;
    user-select: none;
    font-size: 1vw;
  }

  .text-selected {
    padding-left: 5%;
    color: white;
    user-select: none;
    font-size: 1vw;
  }

  .move-menu {
    min-height: 22em;
  }
}
