.location-settings__player--fullscreen {
  width: 100%;
  height: 100%;
  max-width: none;
  margin: 0;
  padding: 0;
  background-color: #222222;

  .row,
  .col,
  .location-settings__player-frame {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.location-settings__container {
  height: 85.2vh;
}


.location-settings__player--fullscreen + .location-settings__controls {
  display: none;
}

.location-settings__editor-container {
  width: 100%;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  color: #ffffff;
  background-color: #222222;
  transition: all 500ms;
}

.location-settings__header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.location-settings__editor-row {
  margin: 0 auto;
  transition: all 500ms;
}

.editor-2__modal .modal-dialog {
  max-width: 1000px;
}


.b-160 {
  bottom: 160px
}

.w-40 {
  width: 40%;
}
