/* This stylesheet generated by Transfonter (https://transfonter.org) on April 15, 2018 8:54 PM */

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ExtraBold.eot');
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
    url('Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Poppins-ExtraBold.woff') format('woff'),
    url('Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Thin.eot');
  src: local('Poppins Thin'), local('Poppins-Thin'),
    url('Poppins-Thin.eot?#iefix') format('embedded-opentype'),
    url('Poppins-Thin.woff') format('woff'),
    url('Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Black.eot');
  src: local('Poppins Black'), local('Poppins-Black'),
    url('Poppins-Black.eot?#iefix') format('embedded-opentype'),
    url('Poppins-Black.woff') format('woff'),
    url('Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Medium.eot');
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('Poppins-Medium.woff') format('woff'),
    url('Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ExtraLight.eot');
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
    url('Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('Poppins-ExtraLight.woff') format('woff'),
    url('Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Regular.eot');
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('Poppins-Regular.woff') format('woff'),
    url('Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-SemiBold.eot');
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Poppins-SemiBold.woff') format('woff'),
    url('Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Light.eot');
  src: local('Poppins Light'), local('Poppins-Light'),
    url('Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('Poppins-Light.woff') format('woff'),
    url('Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Bold.eot');
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('Poppins-Bold.woff') format('woff'),
    url('Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
