.delete-icon {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 20%;
  right: 10%;
  z-index: 1;
  opacity: 0.45;
  &:hover {
    opacity: 1;
  }
}

.plus-icon {
  opacity: 0.45;
  &:hover {
    opacity: 1;
  }
}

.scrollable {
  max-height: 30em;
  overflow: scroll;
  overflow-x: hidden
}

